import React from 'react'
import classNames from 'classnames'

export const LogoFull = ({ className }) => {
  const classes = classNames({
    [className]: className,
  })

  return (
    <svg
      className={classes}
      viewBox="0 0 601 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1799 31.9202C20 36.1984 20 41.799 20 53V119C20 130.201 20 135.802 22.1799 140.08C24.0973 143.843 27.1569 146.903 30.9202 148.82C35.1984 151 40.799 151 52 151H118C129.201 151 134.802 151 139.08 148.82C142.843 146.903 145.903 143.843 147.82 140.08C150 135.802 150 130.201 150 119V53C150 41.799 150 36.1984 147.82 31.9202C145.903 28.1569 142.843 25.0973 139.08 23.1799C134.802 21 129.201 21 118 21H52C40.799 21 35.1984 21 30.9202 23.1799C27.1569 25.0973 24.0973 28.1569 22.1799 31.9202ZM64 54.1781C64 51.16 66.3688 48.7306 69.4768 48.7306H98.3003C101.408 48.7306 103.928 51.1771 103.928 54.1953V76.4455C102.408 75.8139 100.744 75.4657 99 75.4657C91.8203 75.4657 86 81.3658 86 88.6438C86 95.9219 91.8203 101.822 99 101.822C106.18 101.822 112 95.9219 112 88.6438C112 88.5318 111.999 88.4201 111.996 88.3088C111.999 88.2519 112 88.1946 112 88.1369V54.1781C112 46.8906 105.805 41 98.3003 41H69.4768C61.9721 41 55.8886 46.9078 55.8886 54.1953V89.6074C54.3795 88.9861 52.7291 88.6439 51 88.6439C43.8203 88.6439 38 94.5439 38 101.822C38 109.1 43.8203 115 51 115C58.0525 115 63.7934 109.307 63.9945 102.207C63.9981 102.142 64 102.077 64 102.011V101.836L64 101.822L64 101.807V54.1781Z"
        fill="#225CF4"
      />
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.17987 11.9202C0 16.1984 0 21.799 0 33V99C0 110.201 0 115.802 2.17987 120.08C4.09734 123.843 7.15695 126.903 10.9202 128.82C15.1984 131 20.799 131 32 131H98C109.201 131 114.802 131 119.08 128.82C122.843 126.903 125.903 123.843 127.82 120.08C130 115.802 130 110.201 130 99V33C130 21.799 130 16.1984 127.82 11.9202C125.903 8.15695 122.843 5.09734 119.08 3.17987C114.802 1 109.201 1 98 1H32C20.799 1 15.1984 1 10.9202 3.17987C7.15695 5.09734 4.09734 8.15695 2.17987 11.9202ZM64 54.1781C64 51.16 66.3688 48.7306 69.4768 48.7306H98.3003C101.408 48.7306 103.928 51.1771 103.928 54.1953V76.4455C102.408 75.8139 100.744 75.4657 99 75.4657C91.8203 75.4657 86 81.3658 86 88.6438C86 95.9219 91.8203 101.822 99 101.822C106.18 101.822 112 95.9219 112 88.6438C112 88.5318 111.999 88.4201 111.996 88.3088C111.999 88.2519 112 88.1946 112 88.1369V54.1781C112 46.8906 105.805 41 98.3003 41H69.4768C61.9721 41 55.8886 46.9078 55.8886 54.1953V89.6074C54.3795 88.9861 52.7291 88.6439 51 88.6439C43.8203 88.6439 38 94.5439 38 101.822C38 109.1 43.8203 115 51 115C58.0525 115 63.7934 109.307 63.9945 102.207C63.9981 102.142 64 102.077 64 102.011V101.836L64 101.822L64 101.807V54.1781Z"
        fill="#5E4AF9"
      />
      <path
        d="M543.731 139V137.973L552.208 114.804L550.168 114.701L531.225 61.2748V60.196H545.981L558.854 100.163H559.272L572.564 60.196H588V61.2748L559.168 139H543.731Z"
        fill="currentColor"
      />
      <path
        d="M499.984 114.907V72.0628H490.251V60.196H499.984V55.5726C499.984 49.545 501.99 44.7674 506.001 41.2399C509.978 37.7466 515.089 36 521.333 36C523.81 36 526.095 36.2397 528.188 36.7192V48.6374C526.025 48.2949 524.246 48.1237 522.851 48.1237C517.199 48.1237 514.374 50.6067 514.374 55.5726V60.196H527.665V72.0628H514.374V114.907H499.984Z"
        fill="currentColor"
      />
      <path
        d="M433.474 114.907V37.0274H447.812V66.4119H448.283C451.981 61.6515 457.231 59.2713 464.033 59.2713C470.173 59.2713 475.126 61.0179 478.894 64.5112C482.557 68.1072 484.388 72.9533 484.388 79.0493V114.907H469.998V83.005C469.998 75.7102 466.545 72.0628 459.638 72.0628C456.289 72.0628 453.463 73.193 451.161 75.4533C448.928 77.7479 447.812 80.8302 447.812 84.7002V114.907H433.474Z"
        fill="currentColor"
      />
      <path
        d="M400.925 115.831C392.413 115.831 385.732 113.246 380.884 108.074C376.104 102.971 373.715 96.139 373.715 87.577C373.715 78.9466 376.069 72.0628 380.779 66.9257C385.453 61.8228 392.064 59.2713 400.611 59.2713C408.355 59.2713 414.355 61.2577 418.611 65.2304C422.832 69.3744 425.152 74.6485 425.571 81.0528H411.076C410.762 78.2445 409.698 75.9499 407.884 74.1691C406.07 72.4909 403.646 71.6518 400.611 71.6518C392.448 71.6518 388.366 76.9602 388.366 87.577C388.366 98.1596 392.448 103.451 400.611 103.451C403.716 103.451 406.175 102.612 407.989 100.934C409.803 99.3582 410.867 97.1493 411.181 94.3067H425.675C425.257 100.745 422.937 105.934 418.716 109.872C414.46 113.845 408.53 115.831 400.925 115.831Z"
        fill="currentColor"
      />
      <path
        d="M357.073 115.831C351.142 115.831 346.747 114.325 343.886 111.311C341.026 108.4 339.595 104.376 339.595 99.2384V72.217H330.491V60.196H340.171V48.7402L352.886 45.7606H353.985V60.196H367.381V72.217H353.985V96.1561C353.985 98.759 354.561 100.625 355.712 101.756C356.933 102.954 358.747 103.554 361.154 103.554C362.759 103.554 364.538 103.263 366.491 102.68H367.59V114.496C364.73 115.386 361.224 115.831 357.073 115.831Z"
        fill="currentColor"
      />
      <path
        d="M294.592 115.831C288.522 115.831 283.778 114.222 280.359 111.002C276.871 107.92 275.126 103.862 275.126 98.8274C275.126 93.5533 277.01 89.4093 280.778 86.3955C284.51 83.3817 290.127 81.8748 297.627 81.8748C302.581 81.8748 306.715 82.4399 310.029 83.5701V78.8439C310.029 76.4123 309.244 74.4773 307.674 73.0389C306.069 71.5662 303.645 70.8299 300.4 70.8299C297.4 70.8299 295.115 71.4977 293.546 72.8334C291.906 74.2376 291.086 75.9157 291.086 77.8678H277.586C277.586 72.4224 279.609 67.9702 283.656 64.5112C287.633 61.0179 293.319 59.2713 300.714 59.2713C308.145 59.2713 313.936 61.0522 318.087 64.6139C322.308 68.3469 324.419 73.0389 324.419 78.6898V114.907H311.651L310.552 109.204H310.133C306.68 113.622 301.499 115.831 294.592 115.831ZM298.046 104.684C301.709 104.684 304.639 103.708 306.837 101.756C308.965 99.7692 310.029 97.389 310.029 94.6149V93.4334C306.889 92.2347 303.209 91.6354 298.988 91.6354C295.918 91.6354 293.563 92.2347 291.923 93.4334C290.214 94.5978 289.359 96.1732 289.359 98.1596C289.359 100.18 290.109 101.773 291.609 102.937C293.11 104.102 295.255 104.684 298.046 104.684Z"
        fill="currentColor"
      />
      <path
        d="M188 114.907V41.2399H204.274L226.565 78.1761H226.984L249.275 41.2399H265.339V114.907H251.211V64.1003H250.792L227.193 102.578H226.146L202.547 63.792H202.128V114.907H188Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const LogoIcon = ({ className }) => {
  const classes = classNames({
    [className]: className,
  })

  return (
    <svg
      className={classes}
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1799 30.9202C20 35.1984 20 40.799 20 52V118C20 129.201 20 134.802 22.1799 139.08C24.0973 142.843 27.1569 145.903 30.9202 147.82C35.1984 150 40.799 150 52 150H118C129.201 150 134.802 150 139.08 147.82C142.843 145.903 145.903 142.843 147.82 139.08C150 134.802 150 129.201 150 118V52C150 40.799 150 35.1984 147.82 30.9202C145.903 27.1569 142.843 24.0973 139.08 22.1799C134.802 20 129.201 20 118 20H52C40.799 20 35.1984 20 30.9202 22.1799C27.1569 24.0973 24.0973 27.1569 22.1799 30.9202ZM64 53.1781C64 50.16 66.3688 47.7306 69.4768 47.7306H98.3003C101.408 47.7306 103.928 50.1771 103.928 53.1953V75.4455C102.408 74.8139 100.744 74.4657 99 74.4657C91.8203 74.4657 86 80.3658 86 87.6438C86 94.9219 91.8203 100.822 99 100.822C106.18 100.822 112 94.9219 112 87.6438C112 87.5318 111.999 87.4201 111.996 87.3088C111.999 87.2519 112 87.1946 112 87.1369V53.1781C112 45.8906 105.805 40 98.3003 40H69.4768C61.9721 40 55.8886 45.9078 55.8886 53.1953V88.6074C54.3795 87.9861 52.7291 87.6439 51 87.6439C43.8203 87.6439 38 93.5439 38 100.822C38 108.1 43.8203 114 51 114C58.0525 114 63.7934 108.307 63.9945 101.207C63.9981 101.142 64 101.077 64 101.011V100.836L64 100.822L64 100.807V53.1781Z"
        fill="#225CF4"
      />
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.17987 10.9202C0 15.1984 0 20.799 0 32V98C0 109.201 0 114.802 2.17987 119.08C4.09734 122.843 7.15695 125.903 10.9202 127.82C15.1984 130 20.799 130 32 130H98C109.201 130 114.802 130 119.08 127.82C122.843 125.903 125.903 122.843 127.82 119.08C130 114.802 130 109.201 130 98V32C130 20.799 130 15.1984 127.82 10.9202C125.903 7.15695 122.843 4.09734 119.08 2.17987C114.802 0 109.201 0 98 0H32C20.799 0 15.1984 0 10.9202 2.17987C7.15695 4.09734 4.09734 7.15695 2.17987 10.9202ZM64 53.1781C64 50.16 66.3688 47.7306 69.4768 47.7306H98.3003C101.408 47.7306 103.928 50.1771 103.928 53.1953V75.4455C102.408 74.8139 100.744 74.4657 99 74.4657C91.8203 74.4657 86 80.3658 86 87.6438C86 94.9219 91.8203 100.822 99 100.822C106.18 100.822 112 94.9219 112 87.6438C112 87.5318 111.999 87.4201 111.996 87.3088C111.999 87.2519 112 87.1946 112 87.1369V53.1781C112 45.8906 105.805 40 98.3003 40H69.4768C61.9721 40 55.8886 45.9078 55.8886 53.1953V88.6074C54.3795 87.9861 52.7291 87.6439 51 87.6439C43.8203 87.6439 38 93.5439 38 100.822C38 108.1 43.8203 114 51 114C58.0525 114 63.7934 108.307 63.9945 101.207C63.9981 101.142 64 101.077 64 101.011V100.836L64 100.822L64 100.807V53.1781Z"
        fill="#5E4AF9"
      />
    </svg>
  )
}

export const LogoIconPlain = ({ className }) => {
  const classes = classNames({
    [className]: className,
  })

  return (
    <svg
      className={classes}
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1799 30.9202C20 35.1984 20 40.799 20 52V118C20 129.201 20 134.802 22.1799 139.08C24.0973 142.843 27.1569 145.903 30.9202 147.82C35.1984 150 40.799 150 52 150H118C129.201 150 134.802 150 139.08 147.82C142.843 145.903 145.903 142.843 147.82 139.08C150 134.802 150 129.201 150 118V52C150 40.799 150 35.1984 147.82 30.9202C145.903 27.1569 142.843 24.0973 139.08 22.1799C134.802 20 129.201 20 118 20H52C40.799 20 35.1984 20 30.9202 22.1799C27.1569 24.0973 24.0973 27.1569 22.1799 30.9202ZM64 53.1781C64 50.16 66.3688 47.7306 69.4768 47.7306H98.3003C101.408 47.7306 103.928 50.1771 103.928 53.1953V75.4455C102.408 74.8139 100.744 74.4657 99 74.4657C91.8203 74.4657 86 80.3658 86 87.6438C86 94.9219 91.8203 100.822 99 100.822C106.18 100.822 112 94.9219 112 87.6438C112 87.5318 111.999 87.4201 111.996 87.3088C111.999 87.2519 112 87.1946 112 87.1369V53.1781C112 45.8906 105.805 40 98.3003 40H69.4768C61.9721 40 55.8886 45.9078 55.8886 53.1953V88.6074C54.3795 87.9861 52.7291 87.6439 51 87.6439C43.8203 87.6439 38 93.5439 38 100.822C38 108.1 43.8203 114 51 114C58.0525 114 63.7934 108.307 63.9945 101.207C63.9981 101.142 64 101.077 64 101.011V100.836L64 100.822L64 100.807V53.1781Z"
        fill="currentColor"
      />
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.17987 10.9202C0 15.1984 0 20.799 0 32V98C0 109.201 0 114.802 2.17987 119.08C4.09734 122.843 7.15695 125.903 10.9202 127.82C15.1984 130 20.799 130 32 130H98C109.201 130 114.802 130 119.08 127.82C122.843 125.903 125.903 122.843 127.82 119.08C130 114.802 130 109.201 130 98V32C130 20.799 130 15.1984 127.82 10.9202C125.903 7.15695 122.843 4.09734 119.08 2.17987C114.802 0 109.201 0 98 0H32C20.799 0 15.1984 0 10.9202 2.17987C7.15695 4.09734 4.09734 7.15695 2.17987 10.9202ZM64 53.1781C64 50.16 66.3688 47.7306 69.4768 47.7306H98.3003C101.408 47.7306 103.928 50.1771 103.928 53.1953V75.4455C102.408 74.8139 100.744 74.4657 99 74.4657C91.8203 74.4657 86 80.3658 86 87.6438C86 94.9219 91.8203 100.822 99 100.822C106.18 100.822 112 94.9219 112 87.6438C112 87.5318 111.999 87.4201 111.996 87.3088C111.999 87.2519 112 87.1946 112 87.1369V53.1781C112 45.8906 105.805 40 98.3003 40H69.4768C61.9721 40 55.8886 45.9078 55.8886 53.1953V88.6074C54.3795 87.9861 52.7291 87.6439 51 87.6439C43.8203 87.6439 38 93.5439 38 100.822C38 108.1 43.8203 114 51 114C58.0525 114 63.7934 108.307 63.9945 101.207C63.9981 101.142 64 101.077 64 101.011V100.836L64 100.822L64 100.807V53.1781Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const DistroLogo = ({ className }) => {
  const classes = classNames({
    [className]: className,
  })

  return (
    <svg
      className={classes}
      viewBox="0 0 860 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        d="M29.3439 76.2667C29.3439 59.8385 29.3439 51.6244 32.5422 45.3497C35.3555 39.8302 39.8445 35.3428 45.3659 32.5305C51.6429 29.3334 59.8599 29.3334 76.294 29.3334H173.129C189.563 29.3334 197.78 29.3334 204.057 32.5305C209.578 35.3428 214.067 39.8302 216.881 45.3497C220.079 51.6244 220.079 59.8385 220.079 76.2667V173.067C220.079 189.495 220.079 197.709 216.881 203.984C214.067 209.503 209.578 213.991 204.057 216.803C197.78 220 189.563 220 173.129 220H76.294C59.8599 220 51.6429 220 45.3659 216.803C39.8445 213.991 35.3555 209.503 32.5422 203.984C29.3439 197.709 29.3439 189.495 29.3439 173.067V76.2667Z"
        fill="#225CF4"
      />
      <path
        opacity="0.8"
        d="M0 46.9333C0 30.5051 0 22.291 3.19829 16.0163C6.01158 10.4969 10.5006 6.00943 16.022 3.19714C22.299 0 30.516 0 46.9501 0H143.785C160.219 0 168.436 0 174.713 3.19714C180.234 6.00943 184.723 10.4969 187.537 16.0163C190.735 22.291 190.735 30.5051 190.735 46.9333V143.733C190.735 160.162 190.735 168.376 187.537 174.65C184.723 180.17 180.234 184.657 174.713 187.47C168.436 190.667 160.219 190.667 143.785 190.667H46.9501C30.516 190.667 22.299 190.667 16.022 187.47C10.5006 184.657 6.01158 180.17 3.19829 174.65C0 168.376 0 160.162 0 143.733V46.9333Z"
        fill="#5E4AF9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.936 70.0048C97.3757 70.0048 93.9002 73.5679 93.9002 77.9945V147.855L93.9002 147.872L93.9002 147.89V148.15C93.9002 148.246 93.8976 148.342 93.8922 148.438C93.597 158.85 85.1741 167.2 74.8268 167.2C64.2928 167.2 55.7533 158.547 55.7533 147.872C55.7533 137.198 64.2928 128.544 74.8268 128.544C77.3638 128.544 79.7851 129.046 81.9993 129.957V78.0197C81.9993 67.3313 90.9249 58.6666 101.936 58.6666H144.225C155.236 58.6666 164.325 67.3061 164.325 77.9945V127.801C164.325 127.885 164.323 127.969 164.319 128.053C164.323 128.216 164.325 128.38 164.325 128.544C164.325 139.219 155.786 147.872 145.252 147.872C134.718 147.872 126.178 139.219 126.178 128.544C126.178 117.87 134.718 109.216 145.252 109.216C147.811 109.216 150.252 109.727 152.482 110.653V78.0197C152.482 73.5931 148.786 70.0048 144.225 70.0048H101.936Z"
        fill="white"
      />
      <path
        d="M795.674 172.649V171.142L807.992 137.162L805.026 137.011L777.501 58.6523V57.0701H798.943L817.648 115.688H818.256L837.569 57.0701H860V58.6523L818.104 172.649H795.674Z"
        fill="currentColor"
      />
      <path
        d="M732.105 137.313V74.4748H717.963V57.0701H732.105V50.2891C732.105 41.4486 735.02 34.4415 740.849 29.2678C746.628 24.1444 754.054 21.5826 763.128 21.5826C766.727 21.5826 770.047 21.9343 773.088 22.6375V40.1175C769.945 39.6152 767.36 39.3641 765.333 39.3641C757.121 39.3641 753.015 43.0057 753.015 50.2891V57.0701H772.328V74.4748H753.015V137.313H732.105Z"
        fill="currentColor"
      />
      <path
        d="M635.461 137.313V23.0896H656.295V66.1869H656.979C662.352 59.205 669.981 55.714 679.866 55.714C688.787 55.714 695.985 58.2757 701.46 63.3992C706.782 68.6733 709.444 75.7808 709.444 84.7218V137.313H688.534V90.5234C688.534 79.8244 683.515 74.4749 673.479 74.4749C668.613 74.4749 664.507 76.1325 661.161 79.4476C657.917 82.813 656.295 87.3337 656.295 93.0097V137.313H635.461Z"
        fill="currentColor"
      />
      <path
        d="M588.164 138.669C575.796 138.669 566.088 134.876 559.042 127.292C552.098 119.807 548.625 109.786 548.625 97.2289C548.625 84.571 552.047 74.4748 558.89 66.9403C565.683 59.456 575.289 55.7139 587.708 55.7139C598.961 55.7139 607.68 58.6272 613.864 64.4539C619.997 70.5317 623.368 78.2671 623.977 87.6601H602.915C602.459 83.5413 600.913 80.1759 598.277 77.5639C595.641 75.1026 592.118 73.872 587.708 73.872C575.846 73.872 569.915 81.6576 569.915 97.2289C569.915 112.75 575.846 120.511 587.708 120.511C592.219 120.511 595.793 119.28 598.429 116.819C601.065 114.508 602.611 111.268 603.067 107.099H624.129C623.52 116.542 620.15 124.152 614.016 129.929C607.832 135.755 599.214 138.669 588.164 138.669Z"
        fill="currentColor"
      />
      <path
        d="M524.443 138.669C515.826 138.669 509.439 136.459 505.282 132.038C501.126 127.769 499.047 121.867 499.047 114.332V74.7009H485.817V57.0701H499.884V40.2682L518.36 35.8982H519.957V57.0701H539.422V74.7009H519.957V109.812C519.957 113.629 520.793 116.367 522.466 118.024C524.24 119.782 526.876 120.661 530.374 120.661C532.706 120.661 535.291 120.234 538.129 119.38H539.726V136.71C535.57 138.016 530.475 138.669 524.443 138.669Z"
        fill="currentColor"
      />
      <path
        d="M433.654 138.669C424.834 138.669 417.94 136.308 412.972 131.586C407.903 127.066 405.369 121.113 405.369 113.729C405.369 105.994 408.106 99.9162 413.58 95.496C419.004 91.0758 427.165 88.8657 438.064 88.8657C445.262 88.8657 451.269 89.6944 456.084 91.352V84.4203C456.084 80.854 454.944 78.016 452.663 75.9063C450.331 73.7464 446.808 72.6665 442.094 72.6665C437.734 72.6665 434.414 73.646 432.133 75.6049C429.751 77.6644 428.559 80.1256 428.559 82.9887H408.942C408.942 75.0022 411.882 68.4723 417.762 63.3991C423.541 58.2756 431.804 55.7139 442.55 55.7139C453.347 55.7139 461.762 58.3258 467.794 63.5497C473.927 69.0248 476.994 75.9063 476.994 84.1943V137.312H458.441L456.845 128.949H456.236C451.218 135.429 443.69 138.669 433.654 138.669ZM438.672 122.319C443.995 122.319 448.253 120.887 451.446 118.024C454.538 115.111 456.084 111.62 456.084 107.551V105.818C451.522 104.06 446.174 103.181 440.041 103.181C435.58 103.181 432.158 104.06 429.776 105.818C427.292 107.526 426.05 109.837 426.05 112.75C426.05 115.714 427.14 118.049 429.32 119.757C431.499 121.465 434.617 122.319 438.672 122.319Z"
        fill="currentColor"
      />
      <path
        d="M278.766 137.313V29.2678H302.413L334.805 83.4409H335.413L367.804 29.2678H391.147V137.313H370.617V62.7963H370.009L335.717 119.23H334.196L299.904 62.3443H299.296V137.313H278.766Z"
        fill="currentColor"
      />
      <g opacity="0.7">
        <path
          d="M278.766 198.03V167.17H284.644L294.288 184.02H294.46L304.126 167.17H309.896V198.03H304.923V175.369H304.729L294.546 192.822H294.116L283.933 175.24H283.761V198.03H278.766Z"
          fill="currentColor"
        />
        <path
          d="M330.744 198.418C327.056 198.418 324.085 197.377 321.832 195.297C319.579 193.217 318.452 190.312 318.452 186.581V167.17H323.597V186.474C323.597 188.741 324.186 190.505 325.362 191.768C326.511 193.088 328.305 193.748 330.744 193.748C333.199 193.748 335.021 193.088 336.213 191.768C337.361 190.491 337.935 188.726 337.935 186.474V167.17H343.145V186.581C343.145 190.326 342.025 193.231 339.786 195.297C337.533 197.377 334.519 198.418 330.744 198.418Z"
          fill="currentColor"
        />
        <path
          d="M361.733 198.418C357.886 198.418 354.93 197.492 352.863 195.641C350.739 193.819 349.612 191.337 349.483 188.195H354.715C354.844 189.989 355.525 191.373 356.76 192.349C357.937 193.31 359.594 193.791 361.733 193.791C366.268 193.791 368.536 192.327 368.536 189.401C368.536 188.238 368.148 187.313 367.373 186.624C366.584 185.964 365.378 185.462 363.756 185.118L359.257 184.15C356.444 183.533 354.27 182.536 352.734 181.158C351.241 179.81 350.495 177.894 350.495 175.412C350.495 172.816 351.507 170.721 353.531 169.128C355.568 167.565 358.317 166.783 361.776 166.783C365.177 166.783 367.954 167.665 370.107 169.43C372.217 171.194 373.293 173.497 373.336 176.338H368.083C367.997 174.846 367.423 173.655 366.361 172.765C365.314 171.89 363.756 171.453 361.69 171.453C357.786 171.453 355.834 172.773 355.834 175.412C355.834 177.306 357.334 178.59 360.333 179.264L364.51 180.147C370.739 181.424 373.853 184.472 373.853 189.293C373.853 192.119 372.827 194.343 370.774 195.964C368.708 197.6 365.694 198.418 361.733 198.418Z"
          fill="currentColor"
        />
        <path
          d="M380.859 198.03V167.17H386.069V198.03H380.859Z"
          fill="currentColor"
        />
        <path
          d="M407.499 198.418C402.963 198.418 399.462 196.969 396.993 194.07C394.524 191.215 393.29 187.406 393.29 182.643C393.29 177.88 394.524 174.049 396.993 171.151C399.476 168.239 402.978 166.783 407.499 166.783C411.23 166.783 414.244 167.823 416.54 169.903C418.822 171.969 420.114 174.688 420.415 178.059H415.12C414.904 176.079 414.158 174.494 412.881 173.303C411.618 172.127 409.824 171.539 407.499 171.539C404.485 171.539 402.246 172.55 400.782 174.573C399.318 176.639 398.586 179.329 398.586 182.643C398.586 185.972 399.318 188.647 400.782 190.67C402.246 192.693 404.485 193.705 407.499 193.705C409.694 193.705 411.438 193.145 412.73 192.026C414.007 190.921 414.818 189.393 415.163 187.442H420.415C420.028 190.685 418.7 193.332 416.433 195.383C414.194 197.406 411.216 198.418 407.499 198.418Z"
          fill="currentColor"
        />
        <path
          d="M441.79 198.03V167.17H452.21C456.644 167.17 460.211 168.583 462.909 171.41C465.593 174.222 466.935 177.952 466.935 182.6C466.935 187.048 465.622 190.735 462.995 193.662C460.383 196.574 456.788 198.03 452.21 198.03H441.79ZM446.785 193.403H451.801C455.001 193.403 457.441 192.435 459.12 190.498C460.799 188.561 461.639 185.929 461.639 182.6C461.639 179.315 460.799 176.704 459.12 174.767C457.427 172.816 454.987 171.84 451.801 171.84H446.785V193.403Z"
          fill="currentColor"
        />
        <path
          d="M474.156 198.03V167.17H479.366V198.03H474.156Z"
          fill="currentColor"
        />
        <path
          d="M498.406 198.418C494.56 198.418 491.603 197.492 489.537 195.641C487.413 193.819 486.286 191.337 486.157 188.195H491.388C491.517 189.989 492.199 191.373 493.433 192.349C494.61 193.31 496.268 193.791 498.406 193.791C502.941 193.791 505.209 192.327 505.209 189.401C505.209 188.238 504.822 187.313 504.047 186.624C503.257 185.964 502.052 185.462 500.43 185.118L495.93 184.15C493.117 183.533 490.943 182.536 489.408 181.158C487.915 179.81 487.169 177.894 487.169 175.412C487.169 172.816 488.18 170.721 490.204 169.128C492.242 167.565 494.99 166.783 498.449 166.783C501.851 166.783 504.628 167.665 506.781 169.43C508.89 171.194 509.967 173.497 510.01 176.338H504.757C504.671 174.846 504.097 173.655 503.035 172.765C501.987 171.89 500.43 171.453 498.363 171.453C494.459 171.453 492.508 172.773 492.508 175.412C492.508 177.306 494.007 178.59 497.007 179.264L501.183 180.147C507.412 181.424 510.526 184.472 510.526 189.293C510.526 192.119 509.5 194.343 507.448 195.964C505.381 197.6 502.367 198.418 498.406 198.418Z"
          fill="currentColor"
        />
        <path
          d="M524.142 198.03V171.84H514.842V167.17H538.652V171.84H529.351V198.03H524.142Z"
          fill="currentColor"
        />
        <path
          d="M561.696 185.785L569.597 197.6V198.03H563.741L556.292 186.388H550.114V198.03H544.947V167.17H557.993C561.079 167.17 563.533 168.081 565.356 169.903C567.178 171.725 568.09 173.992 568.09 176.704C568.09 178.913 567.523 180.807 566.389 182.385C565.284 183.977 563.72 185.111 561.696 185.785ZM550.114 171.84V181.718H557.692C559.4 181.718 560.663 181.266 561.481 180.362C562.342 179.458 562.772 178.253 562.772 176.747C562.772 175.269 562.342 174.078 561.481 173.174C560.591 172.285 559.328 171.84 557.692 171.84H550.114Z"
          fill="currentColor"
        />
        <path
          d="M575.806 198.03V167.17H581.016V198.03H575.806Z"
          fill="currentColor"
        />
        <path
          d="M590.003 198.03V167.17H603.415C606.142 167.17 608.345 167.88 610.024 169.301C611.703 170.764 612.543 172.729 612.543 175.197C612.543 178.124 611.366 180.226 609.012 181.503V181.675C612.126 182.98 613.684 185.462 613.684 189.121C613.684 191.904 612.815 194.078 611.079 195.641C609.313 197.234 606.902 198.03 603.845 198.03H590.003ZM595.126 179.824H602.446C604.039 179.824 605.245 179.458 606.063 178.726C606.881 178.038 607.29 176.998 607.29 175.606C607.29 174.387 606.909 173.44 606.149 172.765C605.345 172.091 604.211 171.754 602.747 171.754H595.126V179.824ZM595.126 193.403H603.092C604.814 193.403 606.127 192.994 607.031 192.177C607.936 191.402 608.388 190.297 608.388 188.863C608.388 187.457 607.95 186.337 607.074 185.505C606.156 184.716 604.828 184.322 603.092 184.322H595.126V193.403Z"
          fill="currentColor"
        />
        <path
          d="M632.551 198.418C628.863 198.418 625.892 197.377 623.639 195.297C621.386 193.217 620.259 190.312 620.259 186.581V167.17H625.404V186.474C625.404 188.741 625.993 190.505 627.169 191.768C628.318 193.088 630.112 193.748 632.551 193.748C635.006 193.748 636.828 193.088 638.02 191.768C639.168 190.491 639.742 188.726 639.742 186.474V167.17H644.952V186.581C644.952 190.326 643.832 193.231 641.593 195.297C639.34 197.377 636.326 198.418 632.551 198.418Z"
          fill="currentColor"
        />
        <path
          d="M660.095 198.03V171.84H650.795V167.17H674.605V171.84H665.305V198.03H660.095Z"
          fill="currentColor"
        />
        <path
          d="M680.901 198.03V167.17H686.111V198.03H680.901Z"
          fill="currentColor"
        />
        <path
          d="M707.583 198.418C702.962 198.418 699.431 196.94 696.992 193.984C694.552 191.029 693.332 187.234 693.332 182.6C693.332 177.952 694.552 174.15 696.992 171.194C699.417 168.253 702.948 166.783 707.583 166.783C712.233 166.783 715.771 168.253 718.197 171.194C720.608 174.121 721.813 177.923 721.813 182.6C721.813 187.234 720.594 191.029 718.154 193.984C715.714 196.94 712.19 198.418 707.583 198.418ZM707.583 193.662C710.612 193.662 712.858 192.65 714.322 190.627C715.8 188.59 716.539 185.914 716.539 182.6C716.539 179.286 715.8 176.61 714.322 174.573C712.858 172.55 710.612 171.539 707.583 171.539C704.541 171.539 702.288 172.55 700.824 174.573C699.36 176.596 698.628 179.272 698.628 182.6C698.628 185.929 699.36 188.604 700.824 190.627C702.288 192.65 704.541 193.662 707.583 193.662Z"
          fill="currentColor"
        />
        <path
          d="M729.056 198.03V167.17H734.977L748.862 189.831H749.034V167.17H754.136V198.03H748.195L734.309 175.498H734.137V198.03H729.056Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export const PlanetXLogo = ({ className }) => {
  const classes = classNames({
    [className]: className,
  })

  return (
    <svg
      className={classes}
      viewBox="0 0 662 183"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.48779 17.4723C0 24.3175 0 33.2783 0 51.2V131.8C0 149.722 0 158.683 3.48779 165.528C6.55574 171.549 11.4511 176.444 17.4723 179.512C24.3175 183 33.2783 183 51.2 183H131.8C149.722 183 158.683 183 165.528 179.512C171.549 176.444 176.444 171.549 179.512 165.528C183 158.683 183 149.722 183 131.8V51.2C183 33.2783 183 24.3175 179.512 17.4723C176.444 11.4511 171.549 6.55574 165.528 3.48779C158.683 0 149.722 0 131.8 0H51.2C33.2783 0 24.3175 0 17.4723 3.48779C11.4511 6.55574 6.55574 11.4511 3.48779 17.4723ZM134.355 134.005C171.455 96.905 182.462 47.7615 158.94 24.2397C135.418 0.717891 86.2749 11.725 49.1751 48.8249C12.0753 85.9247 1.06818 135.068 24.59 158.59C48.1118 182.112 97.2553 171.105 134.355 134.005Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.079 112.153C72.2474 108.979 74.6699 105.968 76.7731 102.757C78.4654 100.175 80.1038 97.2078 80.456 94.1C80.5157 93.5733 80.5383 93.0439 80.5215 92.515C80.4202 89.3738 79.6573 86.7172 78.0817 84.0153C77.0093 82.1765 75.6782 80.4821 74.38 78.8008C72.8355 76.8011 71.3455 74.8159 70.0351 72.6495C69.753 72.1834 69.8256 71.6008 70.2132 71.2132C70.6008 70.8256 71.1834 70.753 71.6495 71.0351C73.8159 72.3455 75.8011 73.8355 77.8008 75.38C79.4822 76.6781 81.1765 78.0093 83.0153 79.0817C85.7173 80.6572 88.3738 81.4202 91.5151 81.5214C92.0439 81.5383 92.5733 81.5157 93.1 81.456C96.2078 81.1038 99.175 79.4654 101.757 77.773C104.968 75.6699 107.979 73.2474 111.153 71.079C111.624 70.7572 112.239 70.8129 112.64 71.2132C113.04 71.6135 113.096 72.2291 112.774 72.7002C110.605 75.8734 108.183 78.8845 106.08 82.0957C104.387 84.6778 102.749 87.645 102.397 90.7528C102.337 91.2795 102.315 91.8089 102.331 92.3377C102.433 95.479 103.196 98.1355 104.771 100.837C105.843 102.676 107.175 104.371 108.473 106.052C110.017 108.052 111.507 110.037 112.818 112.203C113.1 112.669 113.027 113.252 112.64 113.64C112.252 114.027 111.669 114.1 111.203 113.818C109.037 112.507 107.052 111.017 105.052 109.473C103.371 108.175 101.676 106.843 99.8375 105.771C97.1356 104.196 94.479 103.433 91.3378 103.331C90.8089 103.314 90.2795 103.337 89.7528 103.397C86.645 103.749 83.6778 105.387 81.0958 107.08C77.8845 109.183 74.8734 111.605 71.7002 113.774C71.2291 114.096 70.6135 114.04 70.2132 113.64C69.8129 113.239 69.7573 112.624 70.079 112.153Z"
        fill="currentColor"
      />
      <path
        d="M586.373 46.5H604.776L623.053 78.0126H624.818L643.095 46.5H661.499L636.919 89.3571V91.1218L661.751 134.735H643.348L624.818 102.466H623.053L604.524 134.735H586.121L610.953 91.1218V89.3571L586.373 46.5Z"
        fill="currentColor"
      />
      <path
        d="M532.181 72.2143H548.063V52.8025H563.946V72.2143H581.088V85.8277H563.946V117.34C563.946 119.861 565.206 121.122 567.475 121.122H577.559V134.735H560.416C553.357 134.735 548.063 129.693 548.063 122.634V85.8277H532.181V72.2143Z"
        fill="currentColor"
      />
      <path
        d="M467.744 102.971C467.744 84.063 480.727 70.4496 499.13 70.4496C517.281 70.4496 529.508 83.1807 529.508 101.962V108.139H484.004C484.634 116.458 490.559 122.887 499.634 122.887C508.458 122.887 513.122 117.971 515.265 113.559L528.248 121.122C523.962 127.676 516.651 136.5 499.886 136.5C481.231 136.5 467.744 122.382 467.744 102.971ZM484.13 97.0462H513.374C512.239 89.105 507.197 84.063 499.004 84.063C490.055 84.063 485.391 89.3571 484.13 97.0462Z"
        fill="currentColor"
      />
      <path
        d="M396.683 72.2143H412.313V82.2983H414.078C416.599 76.5 422.397 71.2059 433.868 71.2059C447.733 71.2059 458.195 81.1638 458.195 97.4244V134.735H442.313V98.937C442.313 90.2395 437.271 85.0714 428.195 85.0714C417.985 85.0714 412.565 91.3739 412.565 102.466V134.735H396.683V72.2143Z"
        fill="currentColor"
      />
      <path
        d="M324.132 86.584C331.569 77.2563 340.014 70.4496 355.266 70.4496C372.283 70.4496 383.376 80.1555 383.376 94.7773V134.735H367.745V125.912H365.981C363.964 130.071 359.552 136.5 346.317 136.5C332.199 136.5 322.871 128.937 322.871 116.962C322.871 107.004 331.569 100.071 343.544 98.8109L367.493 96.1639V94.6513C367.493 88.7269 362.955 84.063 354.384 84.063C346.065 84.063 340.897 88.3487 336.989 94.9034L324.132 86.584ZM339.51 116.08C339.51 120.366 343.165 123.391 350.224 123.391C361.317 123.391 367.493 116.836 367.493 108.265V107.256L348.334 109.525C342.409 110.282 339.51 112.424 339.51 116.08Z"
        fill="currentColor"
      />
      <path
        d="M311.996 46.5V134.735H296.113V46.5H311.996Z"
        fill="currentColor"
      />
      <path
        d="M223 46.5H259.303C275.941 46.5 287.538 57.5924 287.538 74.4832C287.538 91.374 275.563 102.466 259.303 102.466H239.639V134.735H223V46.5ZM239.639 61.6261V87.3403H257.664C265.605 87.3403 270.899 82.2983 270.899 74.4832C270.899 66.6681 265.605 61.6261 257.664 61.6261H239.639Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const SpotidoctorIconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 158.5 201">
    <path
      className="cls-1"
      d="M38.1,184.7a3.2,3.2,0,0,1-3.2,3.1,3.1,3.1,0,1,1,0-6.2A3.2,3.2,0,0,1,38.1,184.7Zm10.5-6.6-2.1-.4a1.3,1.3,0,0,1-1.2-1.2c0-.7.8-1.3,1.7-1.3a2,2,0,0,1,2.2,1.9l3.8-.7c-.1-1.8-1.6-4.5-6.1-4.5s-5.8,2.2-5.8,5,1.3,4,4.5,4.7l1.8.4c1.1.3,1.5.7,1.5,1.3s-.6,1.3-1.8,1.3a2.2,2.2,0,0,1-2.4-2.1l-4,.7c.2,1.7,1.7,4.8,6.5,4.8s6.1-2.5,6.1-5.1S51.7,178.7,48.6,178.1Zm33.8-6.2a8.1,8.1,0,1,1,0,16.1,8.1,8.1,0,1,1,0-16.1Zm-3.4,8c0,2.6,1.6,3.8,3.4,3.8s3.4-1.2,3.4-3.8-1.6-3.7-3.4-3.7S79,177.3,79,179.9Zm15.5,3c0,3.1,1.9,4.9,5.1,4.9a5.9,5.9,0,0,0,2.4-.4v-3.9l-1.2.2c-1.1,0-1.7-.3-1.7-1.6v-5.7H102v-4.1H99.1v-4.4H94.9v1.9a2.3,2.3,0,0,1-2.4,2.5h-.7v4.1h2.7Zm10.9,4.7H110V172.3h-4.6Zm2.3-17.2a2.8,2.8,0,1,0-2.7-2.8A2.7,2.7,0,0,0,107.7,170.4Zm5.3,9.5c0-4.5,3.1-8,7.4-8,2.6,0,3.7,1,4.1,1.6v-8.2h4.6v19.5c0,1.4.1,2.4.1,2.8h-4.4a4.9,4.9,0,0,1-.2-1.5,4.9,4.9,0,0,1-4,1.8C116.3,187.9,113,184.6,113,179.9Zm4.7,0c0,2.5,1.6,3.8,3.4,3.8a3.5,3.5,0,0,0,3.5-3.8,3.4,3.4,0,0,0-3.5-3.7A3.3,3.3,0,0,0,117.7,179.9Zm14.5,0a8.1,8.1,0,1,1,8.1,8.1A7.9,7.9,0,0,1,132.2,179.9Zm4.7,0c0,2.6,1.7,3.8,3.4,3.8a3.4,3.4,0,0,0,3.5-3.8,3.5,3.5,0,1,0-6.9,0Zm21.6,8.1a7.2,7.2,0,0,0,7.3-5.3l-4.1-1.2a3.2,3.2,0,0,1-3.2,2.2A3.4,3.4,0,0,1,155,180c0-2.5,1.5-3.8,3.5-3.8a2.9,2.9,0,0,1,3,2.2l4.2-1.2c-.7-2.8-3.1-5.3-7.3-5.3a8.1,8.1,0,1,0,.1,16.1Zm-35.8-65.9a8.1,8.1,0,1,0,7.9,8.1A8,8,0,0,0,122.7,122.1ZM179,49.2V200.3H20.5V-.7H129.2Zm-48.2-1.7H169L130.8,8.8Zm42.4,111.3H76.9A17.8,17.8,0,0,0,86.4,143V104.8L130.6,89v25.4a17.8,17.8,0,1,0,9.4,15.8h0V62.6a4.7,4.7,0,0,0-6.3-4.5L80,77.5A4.8,4.8,0,0,0,76.9,82v45.3a17.8,17.8,0,0,0-7.9-2A17.6,17.6,0,0,0,51.6,143a17.8,17.8,0,0,0,9.5,15.8H26.2v3.8h.1v32.1h30v-1.3h0V172.3h4.5v1.6a5.5,5.5,0,0,1,4.5-2c4.4,0,7.1,3.4,7.1,8s-3,8-7.3,8a5.4,5.4,0,0,1-4.1-1.5v7h0v1.3H173.2V158.8ZM64.3,183.7c1.9,0,3.5-1.2,3.5-3.8a3.5,3.5,0,1,0-6.9,0C60.9,182.4,62.5,183.7,64.3,183.7ZM130.6,69.4l-44.2,16v9.2l44.2-15.9ZM76.9,143a7.9,7.9,0,1,0-7.9,8.1A8,8,0,0,0,76.9,143Z"
      transform="translate(-20.5 0.7)"
    />
  </svg>
)

export default LogoFull
